<template>
  <div class="commonTeacherList">
    <div class="teacherTeamList flex spacearound flexWrap">
      <div class="teacherTeamLists flex alignCenter flexColumn"
           v-for="(jm,jx) in theacherList"
           :key="jx+'c'"
           @click="goother({path:'/teacherdetail', query:{id: jm.id}})">
        <img :src="jm.portrait"
             alt="">
        <div class="teacherTeamListsText fwtHidden c33 fontW">{{jm.real_name}}</div>
        <div class="teacherTeamListsPost moreHiddenthree"
             style="margin-top:5px;width:85%;">{{jm.duties}}</div>
        <!-- <div class="teacherTeamListsPost moreHidden">{{jm.unit}}{{jm.professional}}</div> -->
      </div>
      <div v-for="(km,kx) in showNumber()"
           :key="kx"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'commonTeacherList',
  props: {
    theacherList: {
      type: Array,
      default: () => []
    },

  },
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    showNumber () {
      return this.theacherList.length % 5 == 0 ? this.theacherList.length % 5 : 5 - this.theacherList.length % 5
    }
  },
}
</script>
<style lang="scss" scoped>
.commonTeacherList {
  width: 100%;
  .teacherTeamLists {
    width: 150px;
    height: 300px;
    .teacherTeamListsText {
      line-height: 36px;
    }
    .teacherTeamListsPost {
      font-size: 15px;
      color: #898989;
      line-height: 24px;
    }
    img {
      margin-top: 40px;
      width: 112px;
      height: 112px;
      border-radius: 50%;
      // height: 156px;
    }
  }
}
</style>