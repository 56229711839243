<template>
  <div class="meetingSch">
    <div class="meetingList marginAuto"
         @click="goother({path:'/personal/meetingOrderDetail', query:{orderId:item.id}})"
         v-for="(item,ix) in list"
         :key="ix+'c'">
      <div class="meetingListTitle fontW c33 ellispOne">{{item.meet.start_date}} - {{item.meet.end_date}}</div>
      <div class="meetingNeed c33 ellispOne">{{item.meet.meet_name}}</div>
      <div class="ellispOne"
           style="margin-top: 10px;color: #999999;">
        <img style="width: 15px;height: 15px;margin-right: 5px;"
             src="../assets/img/meeting/icon_02.png"
             alt="">{{item.meet.address}}

      </div>
      <div class="rowBoxTime flex alignCenter"
           style="color: #1DCAD3;margin-top: 10px;"
           :class="item.pay_status == 1 ?'active':''">
        <img src="../assets/img/meeting/icon_01.png"
             v-if='item.pay_type == 2'
             mode=""
             style="width: 15px;height: 15px;margin-right: 5px;">
        <img src="../assets/img/meeting/icon_05.png"
             v-else-if='item.pay_type == 1'
             mode=""
             style="width: 15px;height: 15px;margin-right: 5px;">
        <img src="../assets/img/meeting/icon_04.png"
             v-else-if='item.pay_type == 3'
             mode=""
             style="width: 15px;height: 15px;margin-right: 5px;">
        <img src="../assets/img/meeting/icon_06.png"
             v-else-if='item.pay_type == 4'
             mode=""
             style="width: 15px;height: 15px;margin-right: 5px;">
        <img src="../assets/img/meeting/mianfei.png"
             v-else-if='item.pay_type == 5'
             mode=""
             style="width: 15px;height: 15px;margin-right: 5px;">
        <span> {{payList[item.pay_type-1]}} <template v-if='item.pay_type != 5'>：{{item.pay_status == 1 ?'已支付' : item.pay_status == 2 ?'未支付' :'已取消'}}</template></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'meetingSch',
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      payList: ['支付宝', '微信', '现场支付', '线下支付', '免费'],
    }
  }
}
</script>

<style lang="scss" scoped>
.meetingSch {
  background-color: #fff;
  border-radius: 5px;
  .meetingList {
    width: 324px;
    height: 147px;
    border-bottom: 1px solid #eaeaea;
    overflow: hidden;
    cursor: pointer;
    &:last-child {
      border: none;
    }
  }
  .meetingListTitle {
    text-align: left;
    margin-top: 15px;
    font-size: 18px;
  }
  .meetingNeed {
    text-align: left;
    margin-top: 10px;
    font-size: 16px;
  }
}
.active {
  color: #1dcad3;
}
</style>
