<template>
  <div class="webMain">
    <!-- banner轮播 Start -->
    <div class="carousel_box">
      <el-carousel height="260px"
                   style="width: 1200px;margin: auto;">
        <el-carousel-item v-for="(item,ix) in bannerList"
                          :key="ix">
          <img style="width:100%;height:100%;"
               :src="item.image"
               alt=""
               @click="bannerJump(item)">
        </el-carousel-item>
      </el-carousel>
      <!-- banner轮播 End -->
      <!-- 类型列表 Start -->
      <div class="marginAuto centerWidth typeList flex spacearound flexWrap">
        <div class="typelist flex alignCenter hoverbg"
             v-for="(im,ix) in typeList"
             :key="ix"
             @click="currLevel(im)">
          <img :src="$store.getters.Baseurl + im.icon"
               alt="">
          <span>{{im.name}}</span>
        </div>
        <div class="typelist flex alignCenter hoverbg"
             v-show="typeList.length > 1"
             @click="goother({path:'/allcourse'})">
          <img src="@/assets/img/home/type_list_more.png"
               alt="">
          <span class="c33">更多</span>
        </div>
      </div>
      <!-- 类型列表 End -->
    </div>
    <!-- 课程列表 Start -->
    <div class="courseTyoeList">
      <!-- 热门课程 && 学习计划 Start -->
      <div class="hotCourseANDstudy centerWidth marginAuto flex spacebetween">
        <!-- 热门课程 Start -->
        <div class="hotCourse"
             v-if="hotCourse && hotCourse.length > 0">
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter"><img src="@/assets/img/home/hotcourse.png"
                   style="margin-right:17px"
                   alt=""> 热门课程</div>
            <div class="courseTitleRight c33 flex alignCenter"
                 @click="goother({path:'/More', query:{type:'hotCourse',type_name: '热门课程'}})">
              更多<div style="margin-left:19px;"
                   class="img"></div>
            </div>
          </div>
          <div class="hotCourseBottom flex spacebetween"
               style="margin-top:-23px;">
            <course-list :courseList="hotCourse"
                         :showType="1"
                         :showNumber="3"></course-list>
          </div>
        </div>
        <!-- 热门课程 End -->
        <!-- 学习计划 Start -->
        <div class="studyPlan">
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter">学习计划</div>
            <div class="courseTitleRight c33 flex alignCenter"
                 @click="goother({path:'/studyplan'})">
              更多<div style="margin-left:19px;"
                   class="img"></div>
            </div>
          </div>
          <div class="studyPlanList">
            <div v-show="myPlan.length > 0"
                 class="planList marginAuto"
                 v-for="(im,ix) in myPlan"
                 :key="ix">
              <div class="planListTitle c33 morehidden ">
                {{im.title}}
              </div>
              <div class="planListTag flex alignCenter">
                <img v-show="im.type == 1"
                     class="zhiBG"
                     src="@/assets/img/common/zhiboBG.png"
                     alt="">
                <div class="planListTags flex spacearound alignCenter">
                  {{im.sp_type == 1 ? '单位必修课' : '我的选修课'}}
                </div>
              </div>
              <div class="clear"></div>
              <div class="planListTime flex spacebetween alignCenter">
                <div class="planListTimeDay">
                  {{im.type == 1 ? im.duration : im.plan_time}}
                </div>
                <button class="studyNow cursorP"
                        @click="goother({path:'/play',query:{id: im.id}})">立即学习</button>
              </div>
            </div>
            <div v-show="myPlan.length < 1"
                 style="fontg-size:20px;width:100%;height:100%;"
                 class="c33 flex alignCenter spacearound">暂无学习计划或未登录</div>
            <!-- <temp-data v-show="myPlan.length < 1"></temp-data> -->
          </div>
        </div>
        <!-- 学习计划 End -->
      </div>
      <!-- 热门课程 && 学习计划 End -->
      <!-- 行业活动 && 参会行程 Start -->
      <div class="hotCourseANDstudy centerWidth marginAuto flex spacebetween"
           v-if='industry_Activities && industry_Activities.length >0'>
        <!-- 行业活动 Start -->
        <div class="hotCourse">
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter">
              <!-- <img src="@/assets/img/home/hotcourse.png" style="margin-right:17px" alt=""> -->
              行业活动</div>
            <div class="courseTitleRight c33 flex alignCenter"
                 @click="goother({path:'/More', query:{type:'industry_Activities',type_name: '行业活动'}})">
              更多<div style="margin-left:19px;"
                   class="img"></div>
            </div>
          </div>
          <div class="hotCourseBottom flex spacebetween"
               style="margin-top:-23px;">
            <industry :isHome='1'
                      :list='industry_Activities'></industry>
          </div>
        </div>
        <!-- 行业活动 End -->
        <!-- 参会行程 Start -->
        <div class="studyPlan"
             v-if='meeting_schedule && meeting_schedule.length > 0'>
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter">参会行程</div>
            <div class="courseTitleRight c33 flex alignCenter"
                 @click="goother({path:'/More', query:{type:'meeting_schedule',type_name: '参会行程'}})">
              更多<div style="margin-left:19px;"
                   class="img"></div>
            </div>
          </div>
          <div class="">
            <meeting-sch :list='meeting_schedule'></meeting-sch>
            <!-- <div class="flex alignCenter spacearound" style="width: 100%;height: 100%;">暂无参会行程</div> -->
          </div>
        </div>
        <div class="studyPlan"
             v-else>
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter">参会行程</div>
            <div class="courseTitleRight c33 flex alignCenter">
            </div>
          </div>
          <div style="fontg-size:20px;width:100%;height:296px;background: #fff"
               class="c33 flex alignCenter spacearound">暂无参会行程或未登录</div>
        </div>
        <!-- 参会行程 End -->
      </div>
      <!-- 行业活动 && 参会行程 End -->
      <!-- 第一个广告位 Start -->
      <div class="adev centerWidth marginAuto"
           v-if="advertList && advertList[0] && advertList[0][0]">
        <el-carousel height="159px"
                     style="width:100%;">
          <el-carousel-item style="height:100%;wdith:100%;"
                            v-for="(item,ix) in advertList[0]"
                            :key="ix">
            <div class="centerWidth">
              <img style="width:100%;height:159px;"
                   :src="item.image_pc"
                   alt=""
                   @click="advert(item, 1)">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 第一个广告位 End -->
      <!-- 后台返回类型 Start -->
      <div class="centerWidth marginAuto"
           v-if="homeTypeList && homeTypeList.length > 0">
        <div class="hotCourse"
             v-for="(im,ix) in homeTypeList"
             :key="ix">
          <div class="courseTitle flex spacebetween alignCenter">
            <div class="courseTitleLeft c33 flex alignCenter">{{im.name}}</div>
            <div class="courseTitleRight c33 flex alignCenter"
                 @click="goother({path:'/More', query:{type:'callback',type_id:im.id, type_name: im.name}})">
              更多<div style="margin-left:19px;"
                   class="img"></div>
            </div>
          </div>
          <div class="hotCourseBottom flex spacebetween">
            <course-list :courseList="im.children"
                         :bigCourse="true"
                         :showType="im.type"></course-list>
          </div>
        </div>
      </div>
      <!-- 后台返回类型 End -->
      <!-- 第二个广告位 Start -->
      <div class="adev centerWidth marginAuto"
           v-if="advertList[1] && advertList[1].length > 0">
        <el-carousel height="159px"
                     style="width:100%;">
          <el-carousel-item style="height:100%;wdith:100%;"
                            v-for="(item,ix) in advertList[1]"
                            :key="ix">
            <div class="centerWidth">
              <img style="width:100%;height:159px;"
                   :src="item.image_pc"
                   @click="advert(item, 2)"
                   alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 第二个广告位 End -->
      <!-- 猜你喜欢 Start -->
      <div class="guessLike centerWidth marginAuto"
           v-if="MyLike && MyLike.length > 0">
        <div class="courseTitle flex spacebetween alignCenter">
          <div class="courseTitleLeft c33 flex alignCenter">猜你喜欢</div>
          <div class="courseTitleRight c33 flex alignCenter"
               @click="goother({path:'/More', query:{type:'guessLike',type_name: '猜你喜欢'}})">
            更多<div style="margin-left:19px;"
                 class="img"></div>
          </div>
        </div>
        <div class="guessLikeBox centerWidth marginAuto flex spacearound flexWrap">
          <div v-if="!MyLike">暂无喜欢的课程</div>
          <course-list v-else
                       :courseList="MyLike"
                       :showType="2"></course-list>
        </div>
      </div>
      <!-- 猜你喜欢 End -->
      <!-- 第三个广告位 Start -->
      <div class="adev centerWidth marginAuto"
           v-if="advertList[2] && advertList[2].length > 0">
        <el-carousel height="159px"
                     style="width:100%;">
          <el-carousel-item style="height:100%;wdith:100%;"
                            v-for="(item,ix) in advertList[2]"
                            :key="ix">
            <div class="centerWidth">
              <img style="width:100%;height:159px;"
                   @click="advert(item, 3)"
                   :src="item.image_pc"
                   alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 第三个广告位 End -->
    </div>
    <!-- 课程列表 End -->
    <!-- 讲师团 Start -->
    <div class="teacherTeam centerWidth marginAuto"
         v-if='theacherList && theacherList.length > 0 && theacherList[0].length > 0'>
      <div class="courseTitle flex spacebetween alignCenter">
        <div class="courseTitleLeft c33 flex alignCenter">讲师团</div>
        <div class="courseTitleRight c33 flex alignCenter"
             @click="goother({path:'/More', query:{type:'teacher',type_name: '讲师团'}})">
          更多<div style="margin-left:19px;"
               class="img"></div>
        </div>
      </div>
      <el-carousel :interval="5000"
                   arrow="always">
        <el-carousel-item v-for="(im,ix) in theacherList"
                          :key="ix">
          <div class="teacherTeamList flex spacearound">
            <teacher-list :theacherList="im"></teacher-list>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 讲师团 End -->
    <!-- 人气热销 Start -->
    <div class="widthGray"
         style="height:451px;"
         v-if='goodsRecommend && goodsRecommend.length > 0'>
      <div class="hotcakes centerWidth marginAuto">
        <div class="courseTitle flex spacebetween alignCenter">
          <div class="courseTitleLeft c33 flex alignCenter">人气热销</div>
          <div class="courseTitleRight c33 flex alignCenter"
               @click="goother({path:'/shoplist', query:{type:1, title: '人气热销'}})">
            更多<div style="margin-left:19px;"
                 class="img"></div>
          </div>
        </div>
        <div class="hotcakesList flex spacebetween">
          <shops :shopList="goodsRecommend"></shops>
          <!-- <div class="hotcakesLists flex alignCenter spacebetween flexColumn" v-for="(im,ix) in 5" :key="ix">
            <img src="@/assets/img/common/zhiboBG.png" alt="">
            <div class="hotcakesListsText c33">这里是展品名称区域</div>
            <div class="hotcakesListsPrice flex spacebetween alignCenter">
              <div style="color:#F52121;"><span style="font-size:12px;">￥</span><span style="font-size:24px;">145</span></div>
              <div style="color:#939393;font-size:13px;">199人购买</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 人气热销 End -->
    <div style="padding-bottom:80px;background:#fff;"
         v-if='partnersList && partnersList.length && partnersList[0].length > 0'>
      <div class="centerWidth marginAuto">
        <div class="courseTitle flex  alignCenter ">
          <div class="courseTitleLeft c33  "
               style="width:100%;text-align:center;">合作伙伴</div>
        </div>
        <el-carousel :interval="5000"
                     class="cooperation_box"
                     arrow="always"
                     indicator-position="outside">
          <el-carousel-item v-for="(im,ix) in partnersList"
                            :key="ix"
                            class="cooperation">
            <div v-for="(jm,jx) in im"
                 :key='jx'>
              <img :src="jm.image"
                   alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- <div class="teamwork flex spacebetween flexColumn flexWrap">
        <img src="@/assets/img/home/courseType.png" class="cursorP" style="width:231px;height:106px;" v-for="(im,ix) in 10" :key="ix" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  type_list,
  banner,
  lecturer_list,
  hot_course,
  home_type_list,
  course_by_type,
  course_home_type,
  advert_list,
} from '@/utils/Api/homeList'
import {
  MyCourse_like,
  MyMy_plan,
  course_like_NoToken
} from '@/utils/Api/userList'
import {
  get_goods_recommend
} from '@/utils/Api/goods'
import {
  partners_list
} from '@/utils/Api/commonList'
import { get_meeting_list, get_schedule_list } from '@/utils/Api/meeting'
import meetingSch from '@/components/meetingSch.vue'
import industry from '@/components/Industry.vue'
import courseList from '@/components/courseList'
import teacherList from '@/components/teacherList'
import tempData from '@/components/tempData'
import shops from '@/components/shops.vue'
export default {
  name: 'webMain',
  components: {
    courseList,
    teacherList,
    tempData,
    shops,
    industry,
    meetingSch
  },
  data () {
    return {
      typeList: [],
      theacherList: [],
      bannerList: [],
      MyLike: [], // 猜你喜欢
      hotCourse: [], // 热门课程
      myPlan: [], // 学习计划
      homeTypeList: [], // 后台返回类型
      advertList: [
        [],
        [],
        []
      ], // 广告内容
      partnersList: [], // 合作伙伴
      goodsRecommend: [], // 人气热销
      industry_Activities: [], // 行业动态
      meeting_schedule: [], // 参会行程
    }
  },
  created () {
    this.initial()
  },
  methods: {
    advert (item, type) {
      // advert_list({advert_id:item.id}).then(res => {
      //  console.log(res)
      // })
      if (item.jump_type == 1) {
        if (item.is_have == 1) {
          this.goother({
            path: '/play',
            query: {
              id: item.course_id
            }
          })
          return
        }
        this.$message.warning('课程信息错误！')
      }
      if (item.jump_type == 2) {
        this.goother({
          path: '/advertdetail',
          query: {
            advert_id: item.id,
            type: type
          }
        })
      }
      if (item.jump_type == 3) {
        window.open(item.jump_url)
      }
    },
    bannerJump (item) {
      if (item.jump_type == 1) {
        if (item.is_have == 1) {
          this.goother({
            path: '/play',
            query: {
              id: item.course_id
            }
          })
          return
        }
        this.$message.warning('课程信息错误！')
      }
      if (item.jump_type == 2) {
        this.goother({
          path: '/bannerdetail',
          query: {
            id: item.id
          }
        })
      }
      if (item.jump_type == 3) {
        window.open(item.jump_url)
      }
    },
    currLevel (im) {
      // console.log(im)
      let queryParams = {}
      queryParams.id = im.id
      if (im.level == 2) {
        queryParams.id = im.pid
        queryParams.pid = im.id
      }
      this.goother({
        path: '/allcourse',
        query: queryParams
      })
    },
    async initial () {
      get_goods_recommend({
        page: 1,
        limit: 5
      }).then(res => {
        this.goodsRecommend = res
      })
      try {
        /* banner轮播 */
        this.bannerList = await banner({
          banner_type: 3
        })

        /* 课程分类 */
        this.typeList = await type_list()

        /* 热门课程 */
        let {
          res
        } = await hot_course({
          type: 1
        })
        this.hotCourse = res ? res.slice(0, 3) : []

        /* 学习计划 */
        if (this.$store.getters.token) {
          let myPlan = await MyMy_plan()
          this.myPlan = myPlan.res ? myPlan.res.slice(0, 2) : []
        } else {
          this.myPlan = []
        }

        /* 首页课程分类 */
        let homeTypeList = await home_type_list()
        homeTypeList.forEach((im, ix) => {
          course_home_type({
            home_type_id: im.id
          }).then(res => {
            // console.log(res)
            this.$set(homeTypeList[ix], 'children', [])
            let tempslice = res && res.res ? res.res.slice(0, 4) : []
            homeTypeList[ix].children.push(...tempslice)
            if (homeTypeList[ix].children.length > 0) this.homeTypeList.push(homeTypeList[ix])
          })
        });
        // this.homeTypeList = homeTypeList


        let MyLike
        /* 猜你喜欢 */
        // console.log(this.$store.getters.token)
        if (this.$store.getters.token) {
          MyLike = await MyCourse_like()
          this.MyLike = MyLike.res.slice(0, 4)
        } else {
          MyLike = await course_like_NoToken()
          this.MyLike = MyLike.res.slice(0, 4)
        }
        // 会议  
        let resMeet = await get_meeting_list({ limit: 3, page: 1 })
        this.industry_Activities = resMeet ? resMeet.data : []
        // 行程
        if (this.$store.getters.token) {
          let res1 = await get_schedule_list({ limit: 2, page: 1, type: 1 })
          this.meeting_schedule = res1 ? res1.data : []

        }
        /* 讲师列表  */
        let theacherList = await lecturer_list()
        for (let i = 0; i < theacherList.length; i += 5) {
          this.theacherList.push(theacherList.slice(i, i + 5))
        }
        /* 广告内容 */
        for (let i = 0; i < this.advertList.length; i++) {
          this.advertList[i] = await advert_list({
            type: i + 1
          })
        }


        partners_list().then(res => {
          let data = [];
          for (let index = 0; index <= res.length; index += 10) {
            this.partnersList.push(res.slice(index, index + 10))
          }
        })
        this.$forceUpdate()
      } catch (err) {
        // console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.teamwork {
  width: 100%;
  height: 220px;
  margin-top: 40px;
}
.cooperation_box {
  height: 280px;
  /deep/ .el-carousel__container {
    height: 230px;
    text-align: left;
  }

  /deep/.el-carousel__button {
    height: 3px;
    background-color: #47d7e3;
  }
}
.cooperation {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 230px;

  margin: 0 5px;
  > div {
    img {
      width: 230px;
      height: 105px;
    }
    span {
      position: absolute;
    }
  }
}
.carousel_box {
  width: 100%;
  background-color: #fff;
  padding-bottom: 30px;
}
.hoverbg {
  &:hover {
    cursor: pointer;
    background: url('../assets/img/home/courseType.png') !important;
  }
}

.hotcakes {
  .hotcakesList {
    .hotcakesLists {
      width: 226px;
      height: 310px;
      opacity: 1;
      background: #ffffff;
      border-radius: 9px;

      .hotcakesListsText {
        font-size: 16px;
        text-align: left;
        width: 200px;
      }

      .hotcakesListsPrice {
        width: 200px;
        padding-bottom: 10px;
      }

      img {
        width: 214px;
        height: 214px;
        margin-top: 7px;
      }
    }
  }
}

.teacherTeam {
  height: 375px;

  .teacherTeamList {
    .teacherTeamLists {
      width: 150px;

      .teacherTeamListsText {
        line-height: 36px;
      }

      .teacherTeamListsPost {
        font-size: 15px;
        color: #898989;
        line-height: 36px;
      }

      img {
        width: 112px;
        height: 156px;
      }
    }
  }
}

.guessLike {
  .guessLikeBox {
    height: 360px;
    background: #fff;
    border-radius: 7px;
  }
}

.adev {
  width: 1200px;
  height: 154px;
  margin-top: 25px;
}

.study_zb {
  width: 52px;
  height: 22px;
  float: left;
  margin-right: 10px;
}

.webMain {
  .courseTyoeList {
    width: 100%;
    background: #f7f8fa;
    padding-bottom: 42px;

    .hotCourseANDstudy {
      .studyPlan {
        width: 350px;

        .studyPlanList {
          width: 100%;
          background: #fff;
          border-radius: 8px;
          height: 296px;

          .planList {
            width: 324px;
            padding-top: 12px;
            border-top: 1px solid #eaeaea;
            padding-bottom: 12px;

            &:first-child {
              border: none;
            }

            .planListTime {
              margin-top: 12px;
              width: 100%;

              .planListTimeDay {
                font-size: 14px;
                line-height: 27px;
                color: #9f9f9f;
              }

              .studyNow {
                width: 89px;
                height: 31px;
                opacity: 1;
                background: #47d7e3;
                color: #ffffff;
                font-size: 17px;
                border-radius: 8px;
              }
            }

            .planListTag {
              margin-top: 8px;

              .zhiBG {
                float: left;
              }

              .planListTags {
                width: 81px;
                height: 22px;
                border: 1px solid #ff9a24;
                border-radius: 3px;
                float: left;
                font-size: 14px;
                margin-left: 10px;
                color: #fd9a27;
              }
            }

            .planListTitle {
              width: 309px;
              text-align: left;
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }

      .hotCourse {
        width: 820px;

        .hotCourseBottom {
          width: 100%;
        }
      }
    }
  }

  .typeList {
    margin-top: 42px;

    .typelist {
      width: 233px;
      height: 94px;
      background: #f5f8fc;
      border-radius: 3px;
      margin-top: 9px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin-left: 23px;
      }

      span {
        display: inline-block;
        margin-left: 33px;
        font-size: 18px;
      }
    }
  }
}
</style>
