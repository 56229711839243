import * as api from './http'

/* 商城分类 */
export function get_goods_type(params){
    return api.postApi('goods_home/get_goods_type', params)
}

/* 获取推荐商品 */
export function get_goods_recommend(params){
    return api.postApi('goods_home/get_goods_recommend', params)
}

/* 获取首页板块商品 */
export function get_goods_plate(params){
    return api.postApi('goods_home/get_goods_plate', params)
}

/* 根据分类获取商品列表 */
export function get_goods_list(params){
    return api.postApi('goods_home/get_goods_list', params)
}

/* 商品详情 */
export function get_goods_detail(params){
    return api.postApi('goods_home/get_goods_detail', params)
}

/* 评论列表 */
export function get_goods_comment(params){
    return api.postApi('goods/get_goods_comment', params)
}

/* 加入购物车 */
export function add_cart(params){
    return api.postApi('goods/add_cart', params)
}

/* 立即下单--创建订单 */
export function create_order_pc(params){
    return api.postApi('Goods/create_order_pc', params)
}
