<template>
  <div class="shops flex flexWrap spacebetween">
    <div class="shopInfo flex flexColumn alignCenter"
         v-for="(jm,jx) in shopList"
         :key="jx"
         @click="goother({path:'/shopdetail', query:{id: jm.id}})">
      <img :src="$store.getters.Baseurl + jm.cover_map"
           alt="">
      <div class="shopname c33">{{jm.name}}</div>
      <div class="shopprice flex spacebetween">
        <div class="shoppriceLeft">
          <span>￥</span>{{jm.price}} <span v-show="jm.integral > 0"> + {{jm.integral}} 积分</span>
        </div>
        <div class="shopnum">{{jm.number_of_buyers}}人购买</div>
      </div>
    </div>
    <div v-for="val in 3"
         :key="val + 'q'"
         style="width:220px;"></div>
  </div>
</template>
<script>
export default {
  name: 'shops',
  props: {
    shopList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.shops {
  width: 100%;
  .shopInfo {
    width: 222px;
    height: 304px;
    opacity: 1;
    background: #ffffff;
    border-radius: 9px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      margin-top: 14px;
    }
    .shopname {
      margin-top: 12px;
      font-size: 16px;
      width: 210px;
      text-align: left;
    }
    img {
      width: 210px;
      height: 210px;
      margin-top: 7px;
    }
  }
  .shopprice {
    height: 33px;
    width: 210px;
    .shopnum {
      font-size: 13px;
      color: #939393;
    }
    .shoppriceLeft {
      font-size: 24px;
      color: #f52121;
      span {
        font-size: 12px;
      }
    }
    margin-top: 15px;
    align-items: flex-end;
  }
}
</style>